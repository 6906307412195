var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"car_box"},[_c('el-carousel',{attrs:{"trigger":"click","height":"580px"}},_vm._l((_vm.slideshowList),function(item){return _c('el-carousel-item',{key:item.slideshowId},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.picture,"alt":""}})])}),1)],1),_vm._m(0),_c('div',{staticClass:"hg"}),_c('div',{staticClass:"mask"},[_vm._m(1),_c('div',{staticClass:"content_box"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"our"}),_c('div',{staticClass:"sp_box"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"sp_item",on:{"click":function($event){return _vm.handleRout(item,index)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"our_h3"},[_vm._v(_vm._s(item.dictValue))]),_vm._m(4,true)])}),0),_vm._m(5),_c('div',{staticClass:"hg"}),_vm._m(6),_c('Myfoot')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"us"},[_c('span',{staticStyle:{"color":"#c70000"}},[_vm._v("ABOUT")]),_c('span',{staticStyle:{"color":"#000"}},[_vm._v("US")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_box"},[_c('div',{staticClass:"comp"},[_c('div',{staticClass:"comp_text"},[_c('h4',[_vm._v("COMPANY PROFILE")]),_c('div',{staticClass:"comp_item"},[_vm._v(" HANGZHOU SLOAN SAFETY PROTECTION EQUIPMENT CO., LTD is a professional supplier of roadway safety and personal protection equipment products with purpose \"Making it easier for everyone to be safer \". We speciliazed in Traffic Cone, Road Warning Light, Bollard, Road Stud, Speed Hump, Wheel stopper,Corner Protector;Safety helmet,Safety glasses,Safety gloves,Reflective material, Reflective vest etc.Professional and comprehensive is our competitive. Welcome your enquiry and we promise to give you timely response. If you are interested in our products, please do not hesitate to contact us directly ,We are looking forward to establishing long-term business relationships with you and serving your esteemed company in the near future. ")])]),_c('div',{staticClass:"tap_box"},[_c('span',{staticClass:"view_sp"},[_vm._v("View More")]),_c('div',{staticClass:"el-icon-arrow-right"})])]),_c('div',{staticClass:"right_box"},[_c('div',{staticClass:"right_img"}),_c('div',{staticClass:"right_red"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_who"},[_c('div',{staticClass:"con_box"},[_c('div',{staticClass:"con_item"},[_c('div',{staticClass:"con_head"},[_vm._v("WHO ARE WE?")]),_c('div',{staticClass:"con_text"},[_vm._v(" HANGZHOU SLOAN SAFETY PROTECTION EQUIPMENT CO., LTD，a professional Chines supplier, speciliazed in Traffic safety product and PPE product. ")])]),_c('div',{staticClass:"con_item"},[_c('div',{staticClass:"con_head"},[_vm._v("WHY US?")]),_c('div',{staticClass:"con_text"},[_vm._v(" With sufficient import and export experience and product expertise, we can definitely provide you with a satisfactory import experience. ")])]),_c('div',{staticClass:"con_item"},[_c('div',{staticClass:"con_head"},[_vm._v("WHAT ARE OUR ADVANTAGE?")]),_c('div',{staticClass:"con_text"},[_vm._v(" The founder of the company is a mother of three children who is meticulous, dedicated, passionate about life, and proficient in Spanish and English. More importantly, she has over ten years of experience in this industry. Supported by a strong supply chain team。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_our"},[_c('span',{staticStyle:{"color":"#c70000"}},[_vm._v("OUR")]),_c('span',{staticStyle:{"color":"#000"}},[_vm._v("Products")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"our_text"},[_vm._v(" View More "),_c('i',{staticClass:"el-icon-edit-outline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_our"},[_c('span',{staticStyle:{"color":"#c70000"}},[_vm._v("Contact")]),_c('span',{staticStyle:{"color":"#000"}},[_vm._v("Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact_way"},[_c('div',{staticClass:"contact"},[_c('div',{staticClass:"contact_box"},[_c('div',{staticClass:"contact_item"},[_c('img',{attrs:{"src":require("../assets/home/Group 9.png"),"alt":""}}),_c('div',{staticClass:"contact_text"},[_c('div',[_vm._v("销售经理-电话：")]),_c('div',[_vm._v("+86 136 2545 8542(Whatsapp Available)")])])]),_c('div',{staticClass:"contact_item"},[_c('img',{attrs:{"src":require("../assets/home/Group 9(1).png"),"alt":""}}),_c('div',{staticClass:"contact_text"},[_c('div',[_vm._v("邮件：")]),_c('div',[_vm._v("gerencia@sloansafety.cn")])])]),_c('div',{staticClass:"contact_item"},[_c('img',{attrs:{"src":require("../assets/home/Group 9(2).png"),"alt":""}}),_c('div',{staticClass:"contact_text"},[_c('div',[_vm._v("公司：")]),_c('div',[_vm._v("Hangzhou Housewell Import&Export Co.,ltd")])])])])]),_c('div',[_c('img',{attrs:{"src":require("../assets/home/Mask group(8).png"),"alt":""}})])])
}]

export { render, staticRenderFns }