<template>
  <div id="app">
    <Myheader></Myheader>
    <router-view/>
    
  </div>
</template>
<script>
import Myheader from './components/Myheader.vue'

export default {
  name: 'app',
  components:{Myheader},


}
</script>
<style lang="less">
*{
  padding: 0;
  margin: 0;
}
html, body,#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

</style>
