const en ={
    message:{
      'upload':'upload',
      'placeholderTips':'please Enter',
      'home':'Home',
      'tenantManagement':'Tenant Management',
      'berthManagement':'Berth management',
      'berthInformation':'Berth information',
      'berthRental':'Rental of berths',
      'operationManagement':'Operation Management',
      'waterElectricity':'Water and electricity meter reading',
      'chargeMent':'Charge',
      'noticeMent':'Notice',
      'dataCenter':'Data center',
      'investmentManagement':'Investment Management',
      'managementEnd':'Management end',
      'merchantEnd':'Merchant end',
      'personnelManagement':'Personnel management',
      'department':'Department',
      'peopleManagement':'Personnel management',
    }
  }
  export default en