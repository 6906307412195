<template>
  <div class="test">
    <div class="test_top">
      <div class="test_item">
        <div>
          <img src="../assets/type/bst.png" alt="" />
        </div>
        <div>We've always worked together religiously. Co-operation is the only way to win</div>
      </div>
      <div class="test_item_box">
        <div class="test_item_box_head">product category</div>
        <div class="test_item_box_content">
          <div class="test_content_text"  v-for="(item, index) in dataList" :key="index">{{ item.dictValue }}</div>
          
        </div>
      </div>
      <div class="test_item">
        <div class="test_item_box_head">Contact Us</div>

        <div class="test_content_text">Company Profile</div>
        <div class="test_content_text">Follow us</div>
      </div>
      <div class="test_item">
        <div>联系电话</div>
        <div class="test_dh">+86 13616508089</div>
        <div>(Whatsapp Available)</div>
        <div class="test_content_hz">市场合作</div>
        <div class="test_content_hz_yx">gerencia@sloansafety.cn</div>
      </div>
    </div>
    <div class="test_bottom">
      <div>版权所有©杭州恒禧交通设施有限公司</div>
      <div class="test_right">浙公网安备 33011002016460号浙ICP备2021005650号-1</div>
    </div>
  </div>
</template>

<script>
import {listData} from '../api/index'
export default {
  name: 'My_header',
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        dictType: 'product_type'
      },
      dataList:[]
    }
  },
  mounted(){
    listData(this.queryParams).then(response=>{
      console.log(response.data.data,'response');
      this.dataList = response.data.data
    })
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.test {
  width: 100%;
  height: 480px;
  background-color: #000;
  margin-bottom: 0px;
  padding-top: 50px;
}
.test_top {
  width: 80%;
  margin: 0 auto;
  height: 300px;
  display: flex;
  justify-content: space-between;
}
.test_item {
  width: 20%;
  height: 100%;
  color: #999;
}
.test_item_box {
  width: 30%;
  height: 100%;
}

.test_bottom {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  color: #999999;
  font-size: 16px;
}
.test_right {
  margin-left: 20px;
}
.test_item_box_head {
  width: 153px;
  height: 18px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 16px;
}
.test_item_box_content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  color: #999999;
}
.test_content_text {
  margin-top: 25px;
  text-align: left;
  width: 50%;
}
.test_dh{
height: 34px;
font-size: 24px;
margin-top: 20px;
font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
font-weight: normal;
color: #FFFFFF;
line-height: 28px;
}
.test_content_hz{
    margin-top: 30px;
}
.test_content_hz_yx{
    margin-top:20px;
}
</style>
