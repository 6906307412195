import axios from "axios";
const url='https://sloansafety.com.cn/prod-api'
// 查询字典数据列表
export function listData(query) {
    return axios({
      url: `${url}/system/dict/data/type/product_type`,
      method: 'get',
      params: query
    })
  }

  // 查询轮播图列表
export function listSlideshow(query) {
    return axios({
      url: `${url}/sloan/slideshow/list`,
      method: 'get',
      params: query
    })
  }

  // 查询产品信息列表
export function listInfo(query) {
    return axios({
      url: `${url}/sloan/info/list`,
      method: 'get',
      params: query
    })
  }


