const zhCN ={
    message:{
      'upload':'上传',
      'placeholderTips':'请输入',
      'home':'首页',
      'tenantManagement':'租户管理',
      'berthManagement':'铺位管理',
      'berthInformation':'铺位信息',
      'berthRental':'铺位租赁',
      'operationManagement':'运营管理',
      'waterElectricity':'水电抄表',
      'chargeMent':'收费管理',
      'noticeMent':'公告发布',
      'dataCenter':'数据中心',
      'investmentManagement':'招商管理',
      'managementEnd':'管理端',
      'merchantEnd':'商户端',
      'personnelManagement':'人事管理',
      'department':'部门管理',
      'peopleManagement':'人员管理',
    }
  }
  export default zhCN