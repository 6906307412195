<template>
  <div class="home">
    <div class="car_box">
      <el-carousel trigger="click" height="580px">
        <el-carousel-item v-for="item in slideshowList" :key="item.slideshowId">
          <img style="width:100%; height:100%" :src="item.picture" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="us">
      <span style="color: #c70000">ABOUT</span>
      <span style="color: #000">US</span>
    </div>

    <div class="hg"></div>
    <div class="mask">
      <div class="about_box">
        <div class="comp">
          <div class="comp_text">
            <h4>COMPANY PROFILE</h4>
            <div class="comp_item">
              HANGZHOU SLOAN SAFETY PROTECTION EQUIPMENT CO., LTD is a professional supplier of roadway safety and personal protection equipment products with purpose "Making it easier for everyone to be safer ".
We speciliazed in Traffic Cone, Road Warning Light, Bollard, Road Stud, Speed Hump, Wheel stopper,Corner Protector;Safety helmet,Safety glasses,Safety gloves,Reflective material, Reflective vest etc.Professional and comprehensive is our competitive.
Welcome your enquiry and we promise to give you timely response. If you are interested in our products, please do not hesitate to contact us directly ,We are looking forward to establishing long-term business relationships with you and serving your esteemed company in the near future.
            </div>
          </div>
          <div class="tap_box">
            <span class="view_sp">View More</span>
            <div class="el-icon-arrow-right"></div>
          </div>
        </div>
        <div class="right_box">
          <div class="right_img"></div>
          <div class="right_red"></div>
        </div>
      </div>
      <div class="content_box">
        <div class="content_who">
          <div class="con_box">
            <div class="con_item">
              <div class="con_head">WHO ARE WE?</div>
              <div class="con_text">
                HANGZHOU SLOAN SAFETY PROTECTION EQUIPMENT CO., LTD，a professional Chines supplier, speciliazed in Traffic safety product and PPE product.
              </div>
            </div>
            <div class="con_item">
              <div class="con_head">WHY US?</div>
              <div class="con_text">
                With sufficient import and export experience and product expertise, we can definitely provide you with a satisfactory import experience.
              </div>
            </div>
            <div class="con_item">
              <div class="con_head">WHAT ARE OUR ADVANTAGE?</div>
              <div class="con_text">
                The founder of the company is a mother of three children who is meticulous, dedicated, passionate about life, and proficient in Spanish and English. More importantly, she has over ten years of experience in this industry. Supported by a strong supply chain team。
              </div>
            </div>
          </div>
        </div>
        <div class="content_our">
          <span style="color: #c70000">OUR</span>
          <span style="color: #000">Products</span>
        </div>
        <div class="our"></div>
        <div class="sp_box">
          <div class="sp_item" @click="handleRout(item,index)" v-for="(item, index) in dataList" :key="index">
            <img style="width:100%;" :src="item.img" alt="" />
            <div class="our_h3">{{ item.dictValue }}</div>
            <div class="our_text">
              View More
              <i class="el-icon-edit-outline"></i>
            </div>
          </div>
          
          
          
          
          
          
          
        </div>
        <div class="content_our">
          <span style="color: #c70000">Contact</span>
          <span style="color: #000">Us</span>
        </div>
        <div class="hg"></div>
        <div class="contact_way">
          <div class="contact">
            <div class="contact_box">
              <div class="contact_item">
              <img src="../assets/home/Group 9.png" alt="" />
              <div class="contact_text">
                <div>销售经理-电话：</div>
                <div>+86 136 2545 8542(Whatsapp Available)</div>
              </div>
            </div>
            <div class="contact_item">
              <img src="../assets/home/Group 9(1).png" alt="" />
              <div   class="contact_text">
                <div>邮件：</div>
                <div>gerencia@sloansafety.cn</div>
              </div>
            </div>
            <div class="contact_item">
              <img src="../assets/home/Group 9(2).png" alt="" />
              <div  class="contact_text">
                <div>公司：</div>
                <div>Hangzhou Housewell Import&Export Co.,ltd</div>
              </div>
            </div>
            </div>
            
          </div>
          <div>
            <img src="../assets/home/Mask group(8).png" alt="" />
          </div>
        </div>
        <Myfoot></Myfoot>
      </div>
    </div>
  </div>
</template>

<script>
import Myfoot from '../components/Myfoot.vue'
import {listSlideshow,listData} from '../api/index'
import {EventBus} from '../utlis/Bus'
export default {
  name: 'HomeView',
  components: {Myfoot},
  data() {
    return {
      imgList: [''],
      // 轮播图表格数据
      slideshowList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 999,
        slideshowName: null, //
        sort: null, //
        createDate: null,
        updateDate: null,
        deleted: null,
        picture: null
      },
      queryParams_foot: {
        pageNum: 1,
        pageSize: 9999,
        dictType: 'product_type'
      },
      dataList:[]
    }
  },
  mounted(){
    listSlideshow(this.queryParams).then(response=>{
      this.slideshowList = response.data.rows
      console.log(this.slideshowList,'+++++++++');
    })
    listData(this.queryParams_foot).then(response=>{
      console.log(response.data.data,'response');

      this.dataList = response.data.data.slice(0, -2)
      this.dataList.forEach((item,index)=>{
        item.img=`/img/Mask group(${index}).png`
      })
    })
  },
  methods:{

    handleRout(item,index){
      this.$router.push({ name: 'Product', params: { id: item,index:index } })
       EventBus.$emit('index',2 )
    }
   
  }

}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fafafa !important;
}
.car_box {
  height: 580px;
  width: 100%;
}
.us {
  width: 215px;
  height: 59px;
  font-size: 42px;
  font-family: Alibaba PuHuiTi 2, Alibaba PuHuiTi 20;
  font-weight: normal;

  line-height: 49px;
  margin: 0 auto;
  margin-top: 20px;
}
.view_sp{
  display: inline-block;
  padding: 0 25px;
  line-height: 40px;
}
.hg {
  width: 70px;
  height: 4px;
  background: #c70000;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0 auto;
}
.mask {
  width: 100%;
  height: 900px;
  margin-top: 20px;
}
.about_box {
  display: flex;
  position: relative;
  height: 600px;
}
.comp {
  width: 120%;
  height: 420px;
  background-image: url('../assets/home/Mask group1.png');
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tap_box {
  width: 232px;
  height: 40px;
  background: #c70000;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: absolute;
  top: 537px;
  line-height: 40px;
  color: #fff;
  left: 30%;
  display: flex;
  justify-content: space-between;
}
.el-icon-arrow-right {
  color: #fff;
  background-color: #000;
  width: 40px;
  height: 40px;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
}
.right_box {
  width: 60%;
}
.right_img {
  width: 700px;
  height: 474px;
  background-image: url('../assets/home/Mask group(9).png');
  background-repeat: no-repeat;
  position: absolute;
  top: 40px;
  left: 52%;
  z-index: 2;
}
.right_red {
  width: 1110px;
  height: 276px;
  background: linear-gradient(150deg, #8d1313 0%, #ee2b2b 100%);
  opacity: 1;
  position: absolute;
  top: 300px;
  left: 48%;
}
.comp_text {
  width: 438px;
  height: 308px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.comp_item {
  margin-top: 20px;
}
.content_box {
  width: 100%;
  height: 800px;
  background-color: #fafafa;
}
.content_who {
  width: 80%;
  height: 330px;
  margin: 0 auto;
}
.con_box {
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.con_item {
  width: 370px;
  height: 270px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 10px;
}

.con_head {
  width: 100%;
  height: 32px;
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #c70000;
  line-height: 28px;
}
.con_text {
  width: 355px;
  height: 147px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  margin-top: 20px;
}
.content_our {
  width: 325px;
  height: 59px;
  font-size: 42px;
  margin: 0 auto;
  font-family: Alibaba PuHuiTi 2, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #c70000;
  line-height: 49px;
}
.our {
  width: 70px;
  height: 4px;
  background: #c70000;
  border-radius: 0px 0px 0px 0px;
  margin: 0 auto;
}
.sp_box {
  width: 80%;
  display: flex;
  margin: 0 auto;
  height: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #fafafa;
}
.sp_item {
  width: 360px;
  height: 560px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  margin-top: 20px;
}
.our_h3 {
  width: 245px;
  height: 55px;
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 28px;
  padding: 10px;
}
.our_text {
  padding: 10px;
}
.contact_way {
  width: 80%;
  height: 450px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
}
.contact{
  width: 50%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items:center;
}
.contact_item{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.contact_text{
  width: 80%;
}
.contact_box{
  height: 400px;
  width: 80%;
}
</style>
