<template>
  <div class="test">
    <div class="eng">
      <img src="../assets/home/image 2.png" alt="" />
      <span class="eng_text" @click="setLanguage()">{{ language === 'zh' ? 'English' : '简体中文' }}</span>
    </div>
    <div class="nv">
      <div class="img_box">
        <img src="../assets/type/hs.png" alt="" />
      </div>
      <div class="link_box">
        <div v-for="(item, index) in linkList" :key="index" @click="handleLink(index)">
          <RouterLink  :to="item.path" :class="[flag == index ? 'link_on' : 'link_text']" >
            {{ language === 'zh' ? item.yname : item.name }}
          </RouterLink>
        </div>
      </div>
      <div class="inp_box">
        <!-- <el-input class="inp" :placeholder="[language== 'zh' ? 'SEARCH' : '搜索']"  v-model="value"></el-input><el-button icon="el-icon-search"  class="btnss"  @click="handleSearch"></el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router'
import {EventBus} from '../utlis/Bus'
export default {
  name: 'My_header',
  data() {
    return {
      language: 'zh',
      linkList: [
        {
          name: '首页',
          path: '/Home',
          yname: 'HOME'
        },
        {
          name: '关于我们',
          path: '/Aboutus',
          yname: 'ABOUT US'
        },
        {
          name: '产品分类',
          path: '/Product',
          yname: 'PRODUCTS'
        }
      ],
      flag: 0,
      value: ''
    }
  },
  mounted(){
    EventBus.$on('index', (data) => {
      // 处理事件
      console.log(data);
      this.flag = data
    });
  },
  methods: {
    setLanguage() {
      this.language = this.language === 'zh' ? 'en' : 'zh'
      // 切换语言
      this.$i18n.locale = this.language
      console.log(this.$t('language.zh'))
    },
    handleLink(index) {
      this.flag = index
    }
  },
  components: { RouterLink }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.eng {
  width: 100%;
  height: 25px;
  background: #111111;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.btnss{
}
.eng_text {
  width: 60px;
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  margin-right: 10px;
}
.nv {
  width: 100%;
  height: 78px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.img_box {
  width: 30%;
}
.link_box {
  width: 30%;
  line-height: 78px;
  display: flex;
  justify-content: space-between;
}
.inp_box {
  width: 30%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content:center;
  position: relative;
}

.inp{
  width: 264px;
height: 40px;
}
.link_text {
  color: #000000;
  width: 100%;
  height: 100%;
  display: inline-block;
  text-decoration: none;
}
.link_on {
  color: red;
  width: 100%;
  height: 100%;
  display: inline-block;
  text-decoration: none;
}
</style>
